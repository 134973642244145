import React from "react";
import Loadable from "react-loadable";
import { PulseLoader } from "react-spinners";

function Loader() {
  return <PulseLoader color={"black"} loading={true} size={8} />;
}

const AnalyticsRawLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "component-analytics-raw" */
      /* webpackMode: "lazy" */
      "./../../components/analytics/analytics"
    ),
  loading: Loader,
});

export default AnalyticsRawLoadable;
