import React from "react";

import { PaywallContext } from "../component/paywall";

export const usePaywall = () => {
  const { isVisible, setIsVisible, renderItems, setRenderItems } =
    React.useContext(PaywallContext);

  const showPaywall = (renderItems) => {
    if (renderItems) {
      setRenderItems(renderItems);
    } else {
      setRenderItems({});
    }
    setIsVisible(true);
  };

  const hidePaywall = () => {
    setIsVisible(false);
  };

  return {
    isVisible,
    showPaywall,
    hidePaywall,
  };
};
