import React from "react";
import Loadable from "react-loadable";
import EmptyLoading from "../../components/empty-loading";

const AnalyticsLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "page-analytics" */
      /* webpackMode: "lazy" */
      "./../../components/analytics"
    ),
  loading: EmptyLoading,
});

export default AnalyticsLoadable;
