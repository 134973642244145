import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function NewProjectSkeleton() {
  return (
    <SkeletonTheme>
      <div className="flex justify-center h-screen w-full items-center">
        <div className="text-center mx-4 md:w-auto w-full">
          <Skeleton borderRadius={50} width={200} height={20} />
          <div className="flex flex-col gap-5 justify-around border-2 rounded-[21px] p-4 my-6 h-56 md:w-[600px] w-full ">
            <div className="w-full">
              <Skeleton borderRadius={50} className="w-full" height={30} />
            </div>
            <div className="w-full">
              <Skeleton borderRadius={50} className="w-full" height={30} />
            </div>
          </div>
          <Skeleton borderRadius={50} width={200} height={40} />
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default NewProjectSkeleton;
