import React from "react";
import Loadable from "react-loadable";
import EmptyLoading from "../../components/empty-loading";

const WebsitesLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "page-websites" */
      /* webpackMode: "lazy" */
      "./../domains"
    ),
  loading: EmptyLoading,
});

export default WebsitesLoadable;
