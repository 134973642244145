import React, { useContext, useState } from "react";
import "./index.scss";
import ModalInput from "../../ui-kit/modal";
import { Link, useParams } from "react-router-dom";

export const PaywallContext = React.createContext({});

export const PaywallProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [renderItems, setRenderItems] = useState({});

  const { projectID } = useParams();

  return (
    <PaywallContext.Provider
      value={{
        isVisible,
        setIsVisible,
        renderItems,
        setRenderItems,
      }}
    >
      <ModalInput
        isOpened={isVisible}
        onOutsideClick={() => {
          setIsVisible(false);
        }}
        overlayFrameClassName={"!w-[400px] max-w-[80%]"}
      >
        <div className="p-8 text-center text-grigora-base cursor-default">
          <img
            src="/images/locker.png"
            width={798}
            height={709}
            alt="Limit"
            className="mb-5 w-[200px] mx-auto"
          />
          <h1 className="modal-heading mb-3">
            {renderItems?.title ?? "You have reached limit!"}
          </h1>
          <p className="mb-5 text-gray-500">
            {renderItems?.description ??
              "Please upgrade your plan to continue."}
          </p>
          <div className="text-center flex flex-col items-center gap-2 justify-center">
            <Link
              to={renderItems?.link ?? `settings/billing`}
              onClick={(e) => {
                setIsVisible(false);
              }}
              className="grigora-action-btn w-[200px]"
            >
              Upgrade
            </Link>
            <Link
              to={"mailto:karan@poper.ai"}
              onClick={(e) => {}}
              className="grigora-action-btn transparent small"
            >
              Contact us
            </Link>
          </div>
        </div>
      </ModalInput>
      {children}
    </PaywallContext.Provider>
  );
};
