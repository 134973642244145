import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function AuthSkeleton({ screen }) {
  return (
    <>
      <SkeletonTheme>
        {screen === "forgot" ? (
          <>
            <div className="flex justify-center md:items-center item-start h-[85vh] w-full">
              <div className="flex items-center flex-col md:mt-5 w-full">
                <Skeleton
                  width={80}
                  className="md:mt-12 mt-10"
                  height={80}
                  circle={true}
                />
                <div className="items-center md:w-[500px] w-full h-[350px] md:border-2 border-0 rounded-[21px] flex flex-col md:justify-center justify-start md:mt-12 mt-4 ">
                  <Skeleton
                    borderRadius={50}
                    className="m:mb-8 my-8"
                    width={150}
                    height={20}
                  />
                  <div className="w-full md:px-5 px-10">
                    <Skeleton
                      borderRadius={50}
                      className="w-full mb-5"
                      height={50}
                    />
                  </div>
                  <div className="w-full md:px-5 px-10">
                    <Skeleton
                      borderRadius={50}
                      className="w-full my-5"
                      height={50}
                    />
                  </div>
                  <Skeleton
                    borderRadius={50}
                    className="my-3"
                    width={300}
                    height={5}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-center items-center h-[85vh] width-full">
              <div className="text-center">
                <Skeleton
                  width={80}
                  className="md:mb-8 mt-3"
                  height={80}
                  circle={true}
                />
                <div className="text-center w-[400px] h-[350px] md:border-2 border-0 rounded-[21px] flex flex-col justify-center">
                  <Skeleton
                    borderRadius={50}
                    className="mb-3"
                    width={200}
                    height={10}
                  />
                  <div className="w-full md:px-5 px-12">
                    <Skeleton
                      borderRadius={50}
                      className="my-3 w-full"
                      height={40}
                    />
                  </div>
                  <div className="w-full md:px-5 px-12">
                    <Skeleton
                      borderRadius={50}
                      className="my-3 w-full"
                      height={40}
                    />
                  </div>
                  <div className="w-full md:px-5 px-12">
                    <Skeleton
                      borderRadius={50}
                      className="my-3 w-full"
                      height={40}
                    />
                  </div>
                  <Skeleton
                    borderRadius={50}
                    className="my-3"
                    width={200}
                    height={5}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </SkeletonTheme>
    </>
  );
}

export default AuthSkeleton;
