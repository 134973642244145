import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { PulseLoader } from "react-spinners";

function MainSkeleton() {
  return (
    <SkeletonTheme>
      <div className="flex w-full h-screen justify-center items-center">
        <PulseLoader color="black" size={7} />
      </div>
    </SkeletonTheme>
  );
}

export default MainSkeleton;
