import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import NavigationSkeleton from "./navigation-skeleton";
import ProjectCardSkeleton from "../../pages/projects/projectSkeleton";

function ProjectsSkeleton() {
  return (
    <SkeletonTheme>
      <div className="flex flex-1 w-full md:p-16 md:py-6 items-center flex-col">
        <div className="flex w-full justify-between items-center">
          <Skeleton borderRadius={10} width={120} height={50} />
          <div className="flex gap-2 items-center">
            <Skeleton borderRadius={50} width={150} height={10} />
            <Skeleton borderRadius={50} width={150} height={10} />
            <Skeleton borderRadius={50} width={150} height={10} />
          </div>
        </div>
        <div className="w-full mt-[30px]">
          <ProjectCardSkeleton />
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default ProjectsSkeleton;
