import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Card = () => {
  return (
    <div className="w-full justify-center p-4 h-[72px] border-2 rounded-[10px] flex flex-col">
      <Skeleton width={"100%"} height={18} count={1} />
      <div className="flex gap-2 w-full"></div>
    </div>
  );
};
function ProjectCardSkeleton() {
  return (
    <SkeletonTheme>
      <div className="w-full flex justify-start flex-wrap gap-4 mt-4">
        {Array(6)
          .fill(0)
          .map((item, index) => {
            return <Card key={`project-cards-skeleton-${index}`} />;
          })}
      </div>
    </SkeletonTheme>
  );
}

export default ProjectCardSkeleton;
