import Loadable from "react-loadable";
import MainSkeleton from "../../components/skeletons/main-skeleton";

const StartNewLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "page-start-new" */
      /* webpackMode: "lazy" */
      "./../start-new"
    ),
  loading: MainSkeleton,
});

export default StartNewLoadable;
