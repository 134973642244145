import React, { useEffect } from "react";

const LinkedInTracker = () => {
  useEffect(() => {
    // LinkedIn partner ID
    window._linkedin_partner_id = "6404316";
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(window._linkedin_partner_id);

    // Create script element and set its source
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";

    // LinkedIn tracking function
    const linkedInFunc = function (l) {
      if (!l) {
        window.lintrk = function (a, b) {
          window.lintrk.q.push([a, b]);
        };
        window.lintrk.q = [];
      }
    };

    // Append script to document
    document.body.appendChild(script);

    // Call LinkedIn function
    linkedInFunc(window.lintrk);

    // Cleanup function to remove the script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <noscript>
      <img
        height="1"
        width="1"
        style={{ display: "none" }}
        alt=""
        src="https://px.ads.linkedin.com/collect/?pid=6404316&fmt=gif"
      />
    </noscript>
  );
};

export default LinkedInTracker;
