import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function StartNewProjectSkeleton() {
  return (
    <SkeletonTheme>
      <div className="flex w-full h-screen justify-center items-center">
        <div className="text-center w-full">
          <Skeleton
            borderRadius={50}
            className="my-5"
            width={600}
            height={40}
          />
          <Skeleton
            borderRadius={50}
            className="my-5"
            width={500}
            height={40}
          />
          <Skeleton
            borderRadius={50}
            className="my-5"
            width={150}
            height={40}
          />
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default StartNewProjectSkeleton;
