import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function NotFoundSkeleton() {
  return (
    <SkeletonTheme>
      <div className="flex w-full items-center h-screen">
        <div className="md:ml-28 ml-14">
          <Skeleton
            borderRadius={50}
            width={100}
            height={20}
            className="mb-5"
          />
          <Skeleton
            borderRadius={50}
            width={200}
            height={10}
            className="mt-7"
          />
          <Skeleton
            borderRadius={50}
            width={200}
            height={10}
            className="mt-7"
          />
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default NotFoundSkeleton;
