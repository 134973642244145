import React from "react";
import Loadable from "react-loadable";
import EmptyLoading from "../../components/empty-loading";

const TeamsLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "page-teams" */
      /* webpackMode: "lazy" */
      "./../teams"
    ),
  loading: EmptyLoading,
});

export default TeamsLoadable;
