import React from "react";
import Loadable from "react-loadable";
import EmptyLoading from "../../components/empty-loading";

const CodeEmbedLoadable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "component-code-embed" */
      /* webpackMode: "lazy" */
      "../../components/code/code-embed"
    ),
  loading: EmptyLoading,
});

export default CodeEmbedLoadable;
