import React, { useContext, useEffect, useState } from "react";
import {
  Navigate,
  createSearchParams,
  useLocation,
  useParams,
  useSearchParams,
  matchPath,
} from "react-router-dom";
import { AccountContext } from "./account";
import { APIProvider } from "../api";
import { PaywallProvider } from "../paywall";
import { PoperAccountProvider } from "../poper-account";

function LoginRequired({ redirect, not = false, bypass = false, children }) {
  const { currentUser, sessionLoaded } = useContext(AccountContext);
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const [returnTo] = useState(
    searchParams.get("return")?.replace(
      // Replace | with & to make it a valid query string
      /\|/g,
      "&",
    ),
  );

  if (bypass) {
    return sessionLoaded ? (
      children
    ) : (
      <></>
      // (activeScreen === "dashboard" ? <DashboardSkeleton key={"dashboard-skeleton"} /> : activeScreen === "editor" ? <EditorSkeleton key={"loading"} /> : activeScreen === "cms/addpost" || activeScreen === "cms/editpost" ? <PostEditSkeleton key={"loading"} /> : <></>)
    );
  }

  if (not && sessionLoaded && currentUser) {
    if (returnTo && returnTo.startsWith("http")) {
      window.location = returnTo;
    }
  }

  return not ? (
    sessionLoaded ? (
      currentUser ? (
        <Navigate replace to={returnTo ? returnTo : redirect} />
      ) : (
        children
      )
    ) : (
      <></>
      // (activeScreen === "dashboard" ? <DashboardSkeleton key={"dashboard-skeleton"} /> : activeScreen === "editor" ? <EditorSkeleton key={"loading"} /> : activeScreen === "cms/addpost" || activeScreen === "cms/editpost" ? <PostEditSkeleton key={"loading"} /> : <></>)
    )
  ) : sessionLoaded ? (
    currentUser ? (
      <PaywallProvider>
        <APIProvider>
          <PoperAccountProvider>{children}</PoperAccountProvider>
        </APIProvider>
      </PaywallProvider>
    ) : (
      <Navigate
        replace
        to={{
          pathname: redirect,
          search: createSearchParams({
            return: location.pathname + location.search,
          }).toString(),
        }}
      />
    )
  ) : (
    <></>
    // (activeScreen === "dashboard" ? <DashboardSkeleton key={"dashboard-skeleton"} /> : activeScreen === "editor" ? <EditorSkeleton key={"editor-skeleton"} /> : activeScreen === "cms/addpost" || activeScreen === "cms/editpost" ? <PostEditSkeleton key={"posteditor-skeleton"} /> : <></>);
  );
}

export default LoginRequired;
