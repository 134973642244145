import { CookieStorage } from "amazon-cognito-identity-js";

const COGNITO_STORAGE_OBJECT = {
  ...(process.env.REACT_APP_AWS_COOKIE_KEY
    ? {
        Storage: new CookieStorage({
          domain: process.env.REACT_APP_AWS_COOKIE_KEY,
        }),
      }
    : {}),
};

const AWSCognitoPoolData = {
  UserPoolId: process.env.REACT_APP_AWS_COGNITO_POOL,
  ClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT,
  ...COGNITO_STORAGE_OBJECT,
};

const websiteConfig = {
  supportEmail: "karan@poper.ai",
};
export { AWSCognitoPoolData, websiteConfig, COGNITO_STORAGE_OBJECT };
