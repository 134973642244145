function modulus(divident, divisor) {
  return ((divident % divisor) + divisor) % divisor;
}

function remove_prefix(string, prefix) {
  return string && string.startsWith(prefix)
    ? string.slice(prefix.length)
    : string;
}

function trimPageData(string) {
  var trim = "page-data-";
  return string.startsWith(trim) ? string.slice(trim.length) : string;
}

function trimProjectMeta(string) {
  var trim = "project-meta-";
  return string.startsWith(trim) ? string.slice(trim.length) : string;
}

function trimPageContent(string) {
  var trim = "page-content-";
  return string.startsWith(trim) ? string.slice(trim.length) : string;
}

function makeID(length) {
  var result = "";
  var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function isEmptyObject(obj) {
  return (
    obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
}

function limitTextSize(text, length = 20, suffix = "…") {
  if (!text) {
    return "";
  }
  if (text.length < length + 1) {
    return text;
  }
  return text.slice(0, length + 1) + suffix;
}

/**
 * Normalizes a given string of HTML to remove the Windows-specific "Fragment"
 * comments and any preceding and trailing content.
 *
 * @param {string} html the html to be normalized
 * @return {string} the normalized html
 */
function removeWindowsFragments(html) {
  const startStr = "<!--StartFragment-->";
  const startIdx = html.indexOf(startStr);
  if (startIdx > -1) {
    html = html.substring(startIdx + startStr.length);
  } else {
    // No point looking for EndFragment
    return html;
  }

  const endStr = "<!--EndFragment-->";
  const endIdx = html.indexOf(endStr);
  if (endIdx > -1) {
    html = html.substring(0, endIdx);
  }

  return html;
}

/**
 * Removes the charset meta tag inserted by Chromium.
 * See:
 * - https://github.com/WordPress/gutenberg/issues/33585
 * - https://bugs.chromium.org/p/chromium/issues/detail?id=1264616#c4
 *
 * @param {string} html the html to be stripped of the meta tag.
 * @return {string} the cleaned html
 */
function removeCharsetMetaTag(html) {
  const metaTag = `<meta charset='utf-8'>`;

  if (html.startsWith(metaTag)) {
    return html.slice(metaTag.length);
  }

  return html;
}

/**
 * Gets all files from a DataTransfer object.
 *
 * @param {DataTransfer} dataTransfer DataTransfer object to inspect.
 *
 * @return {File[]} An array containing all files.
 */
function getFilesFromDataTransfer(dataTransfer) {
  const files = Array.from(dataTransfer.files);

  Array.from(dataTransfer.items).forEach((item) => {
    const file = item.getAsFile();

    if (
      file &&
      !files.find(
        ({ name, type, size }) =>
          name === file.name && type === file.type && size === file.size,
      )
    ) {
      files.push(file);
    }
  });

  return files;
}

/**
 * Handle the case where pasted clipboard has screenshot image file generated by the
 * softwares like MS Docs.
 */
export function shouldDismissPastedFiles(files, html) {
  if (html && files?.length === 1 && files[0].type.indexOf("image/") === 0) {
    // A single <img> tag found in the HTML source suggests that the
    // content being pasted revolves around an image. Sometimes there are
    // other elements found, like <figure>, but we assume that the user's
    // intention is to paste the actual image file.
    const IMAGE_TAG = /<\s*img\b/gi;

    // Even when there is exactly one <img> tag in the HTML payload, we
    // choose to weed out local images, i.e. those whose source starts with
    // "file://". These payloads occur in specific configurations, such as
    // when copying an entire document from Microsoft Word, that contains
    // text and exactly one image, and pasting that content using Google
    // Chrome.
    const IMG_WITH_LOCAL_SRC = /<\s*img\b[^>]*\bsrc="file:\/\//i;

    if (html.match(IMAGE_TAG)?.length !== 1 || html.match(IMG_WITH_LOCAL_SRC))
      return true;
  }

  return false;
}

function isJsonString(str) {
  if (str === null || str === undefined) {
    return false;
  }

  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const fromEntries = (pairs) => {
  if (Object.fromEntries) {
    return Object.fromEntries(pairs);
  }
  return pairs.reduce(
    (accum, [id, value]) => ({
      ...accum,
      [id]: value,
    }),
    {},
  );
};

function removeTrailingSlash(str) {
  return str ? str.replace(/\/+$/, "") : str;
}

const segmenter =
  Intl.Segmenter && new Intl.Segmenter("en", { granularity: "sentence" });

function getSentencesFromText(str) {
  if (!str) {
    return [];
  }

  // Intl Segmenter is not available in Firefox
  if (segmenter) {
    return Array.from(segmenter.segment(str), (s) => s.segment);
  }

  return str.match(/[^\.!\?]+[\.!\?]+/g) ?? [];
}

function isDevModeOn(email) {
  if (email === "") {
    return false;
  }

  if (process.env.REACT_APP_NODE_ENV === "development") {
    return true;
  }

  if (localStorage.getItem(email + "-devMode") === "true") {
    return true;
  }

  return false;
}

function capitalizeFirstLetter(string) {
  if (typeof string !== "string") return "";

  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getInitialReactState(key, defaultValue = "", isObject) {
  try {
    const value = localStorage.getItem("poper-react-state-" + key);

    if (value === null) {
      return defaultValue;
    }

    try {
      return JSON.parse(value);
    } catch (error) {
      if (isObject) {
        return defaultValue;
      }
      return value;
    }
  } catch (error) {
    console.error(error);
    return defaultValue;
  }
}

function setInitialReactState(key, value, isObject = false) {
  try {
    if (isObject) {
      localStorage.setItem("poper-react-state-" + key, JSON.stringify(value));
      return;
    }

    localStorage.setItem("poper-react-state-" + key, value);
  } catch (error) {
    console.error(error);
  }
}

function removeInitialReactStates(keys = []) {
  try {
    keys.forEach((key) => {
      localStorage.removeItem("poper-react-state-" + key);
    });
  } catch (error) {
    console.error(error);
  }
}

export {
  remove_prefix,
  modulus,
  trimPageData,
  trimProjectMeta,
  trimPageContent,
  makeID,
  isEmptyObject,
  removeWindowsFragments,
  removeCharsetMetaTag,
  getFilesFromDataTransfer,
  isJsonString,
  fromEntries,
  removeTrailingSlash,
  limitTextSize,
  getSentencesFromText,
  isDevModeOn,
  capitalizeFirstLetter,
  getInitialReactState,
  setInitialReactState,
  removeInitialReactStates,
};
